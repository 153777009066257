<template>
  <a-radio-group v-model="listLocal">
      <a-tabs default-active-key="1">
      <a-tab-pane :tab="positionCB.title" v-for="positionCB in values" :key="positionCB.key">
        <div v-for="child in positionCB.children" :key="child.value">
          <a-radio :style="radioStyle" :value="child.value">
          {{ child.value }}
          </a-radio>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="values.length + 2" tab="Egendefinert" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <a-radio :style="radioStyle" :value="'custom'" >
          Egendefinert Stilling
          </a-radio>
      </a-tab-pane>
      </a-tabs>
  </a-radio-group>
</template>

<script>
export default {
  props: ['list', 'values'],
  model: {
    prop: 'list',
    event: 'listchange',
  },
  computed: {
    listLocal: {
      get: function() {
        return this.list
      },
      set: function(value) {
        this.$emit('listchange', value)
      },
    },
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
}
</script>
