<template>
    <a-form @submit.prevent="createUser()" v-if="loaded">
        <h2>Onboarding Sky Gruppen</h2>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Ansettelsestype</h5>
          <div class="row mb-3" v-if="$store.state.user.companies.length > 1">
            <div class="col-md-6">
              <a-form-item label="Velg selskap">
                <a-select :options="companies" v-model="selectedCompany"/>
              </a-form-item>
            </div>
          </div>
          <div class="row">
              <div class="col-lg-12">
                <tab-selector v-model="position.type" :values="positionsSelector.filter(el => el.company === selectedCompany)" v-if="$store.state.user.companies.length > 1"/>
                <tab-selector v-model="position.type" :values="positionsSelector" v-else />
              </div>
          </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <div class="row" v-if="position.type === 'custom'">
          <div class="col-md-6">
            <a-form-item label="Stillingsnavn">
            <a-input placeholder="Stillingsnavn" v-model="customType"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Selskap">
            <a-input placeholder="Selskap" v-model="position.company"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Prosjekt">
            <a-input placeholder="Prosjekt" v-model="position.projectID"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Stillingstype">
            <a-input placeholder="Fulltid/Deltid" v-model="position.employmentType"/>
            </a-form-item>
          </div>
          </div>
          <div class="row">
            <div class="col-md-4" v-if="['HRcreateUsersAll'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Avdeling">
                <a-select
                  v-model="position.department"
                  :options="avDepartments"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.startDate')" :required="true">
                <a-date-picker :default-value="moment(new Date(), 'YYYY-MM-DD')"  v-model="position.startDate"/>
                </a-form-item>
            </div>
          </div>
          <div class="row" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            <div class="col-md-4">
              <a-form-item label="Stillingsprosent (i %)" :required="true">
                <a-input
                  :value="position.percentage"
                  placeholder="Prosent Stilling"
                  :max-length="3"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Lønnsmodell">
                <a-select v-model="position.salary.type" :options="salaryTypes"/>
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Garanti / Fastlønn (i kr)" :required="true">
                <a-input
                  v-model="position.salary.amount"
                  placeholder="Beløp"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Personalia</h5>
            <div class="row">
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.name')" :required="true">
                <a-input :placeholder="$t('userInfo.name')" v-model="data.name" @change="generateUsernames"/>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.surname')" :required="true">
                <a-input :placeholder="$t('userInfo.surname')" v-model="data.surname" @change="generateUsernames"/>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.email')" :required="true">
                <a-input :placeholder="$t('userInfo.email')" type="email" v-model="data.email" />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.phone')" :required="true">
                <phone-form-component v-model="data.phone"/>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.socialsecurity')" :required="true">
                <a-input :placeholder="$t('userInfo.socialsecurity')" v-model="data.personalNumber"  @change="fnrValidate(data.personalNumber)" :maxLength="12" :class="{ validfield: fnrValid, invalidfield: fnrError }"/>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.bankaccount')" :required="true">
                <a-input :placeholder="$t('userInfo.bankaccount')" v-model="data.bankAccount" @change="bankValidate" :maxLength="13" :class="{ validfield: bankValid, invalidfield: bankError }"/>
                </a-form-item>
            </div>
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.adress')" :required="true">
                <a-input :placeholder="$t('userInfo.adress')" v-model="data.adress"/>
                </a-form-item>
            </div>
            <div class="col-md-2">
                <a-form-item :label="$t('userInfo.zip')" :required="true">
                <a-input v-model="data.zip" :maxLength="4" @change="data.city = findCity(data.zip)"/>
                </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.city')" >
                {{ data.city }}
                </a-form-item>
            </div>
            </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <a-form-item label="Notater">
            <a-textarea placeholder="Notater" :rows="4" v-model="data.notes"/>
          </a-form-item>
        </div>
        </div>
        <div class="card" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Brukere</h5>
            <div class="row">
              <div class="col-md-6" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
                <a-form-item label="Lage Office 365 bruker?">
                <a-radio-group v-model="create365">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Bedrifts e-post">
                <a-input :placeholder="$t('userInfo.email')" type="email" v-model="data.companyEmail" />
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Kontrakt</h5>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Lage Kontrakt?">
                <a-radio-group v-model="createContract">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="row" v-if="['underDevelopment'].some(r => roles.indexOf(r) >= 0)">
              <div class="col-md-6">
                <a-form-item label="Egendefinert AnsattID?">
                <a-radio-group v-model="createCustomID">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Egendefinert ansattID">
                <a-input type="text" v-model="customID" />
                </a-form-item>
              </div>
          </div>
          </div>
        </div>
          <div class="card">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Referert</h5>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Har ansatten blitt referert?">
                <a-radio-group v-model="isReferred" @change="fetchUsers">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-6" v-if="isReferred">
                <a-form-item label="Referert av">
                  <a-auto-complete
                  v-model="data.referredBy"
                  :data-source="users"
                  :filter-option="filterOption"
                  placeholder="finn ansatt..."
                />
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
      <div class="form-actions mt-10">
      <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loading">Onboard Ansatt</a-button>
      <a-button style="width: 150px;" type="error" class="mr-3" @click="resetForm">Reset</a-button>
      </div>
    </a-form>
</template>

<script>
import { firestore } from '@/services/firebase'

import moment from 'moment'
import { mapState } from 'vuex'
import { openNotification, create365API } from '@/services/powerAutomate'
import { createRecruitment } from '@/services/common/payroll'
import { calculateBirthday, findCity, createContract, selectAvDepartments, salaryTypes } from '@/services/common/hr'
import phoneFormComponent from '@/components/custom/forms/formComponents/telephone'
import validator from 'norsk-validator'
import tabSelector from '@/components/custom/visual/tabSelector'

function formatNumber(value) {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

export default {
  components: {
    tabSelector,
    phoneFormComponent,
  },
  props: ['data'],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      salaryTypes,
      loaded: false,
      roles: this.$store.state.user.roles,
      role: this.$store.state.user.role,
      value: null,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      createContract: true,
      create365: true,
      loading: false,
      fnrValid: false,
      fnrError: false,
      bankValid: false,
      bankError: false,
      customType: '',
      customID: '',
      createCustomID: false,
      isReferred: false,
      users: [],
      practicant: false,
      showPercentage: false,
      positionsSelector: [],
      contractTypes: [],
      selectedContract: {},
      company: {},
      avDepartments: [],
      companies: [],
      selectedCompany: '',
      position: {
        salary: {},
      },
    }
  },
  mounted() {
    this.$store.state.user.companies.forEach(company => {
      firestore.collection('settings').doc('companies').collection('companies').doc(company).get()
        .then(doc => {
          this.companies.push({
            label: doc.data().displayName,
            value: doc.id,
            emailDomain: doc.data().emailDomain,
          })
        })
    })
    if (this.$store.state.user.companies.length === 1) {
      this.selectedCompany = this.$store.state.user.companies[0]
    }
    this.fetchContracts()
  },
  methods: {
    moment,
    formatNumber,
    selectCompany(companyId) {
      console.log(companyId)
    },
    async createUser() {
      console.log('phonennumber', this.data.phone)
      const referredBy = this.users.find(user => user.value === this.data.referredBy)
      if (this.isReferred && !referredBy) {
        openNotification('Ugyldig referent', 'Du må velge en gyldig referent')
        return
      }
      this.fnrValidate(this.data.personalNumber)
      this.bankValidate()
      if (!this.fnrValid && !this.bankValid && !this.position.type) {
        openNotification('Ugyldig Fødselsnummer, d-nummer eller kontonummer', 'Fødselsnummer, D-nummer eller kontonummer er ugyldig, vennligst rett opp.')
        return
      }
      this.loading = true
      const data = this.data
      const position = this.position
      let fnrExists = 0
      await firestore.collection('users').where('personalNumber', '==', data.personalNumber).get().then(snapshot => {
        fnrExists = snapshot.docs.length
      })
      console.log(fnrExists)
      if (fnrExists) {
        openNotification('Fødselsnummer allerede i database', 'En person med samme fødselsnummer er allerede registrert i databasen. Vennliigst prøv igjen med en annen, eller reaktiver gammel bruker.')
        this.loading = false
        return
      }
      data.type = 'employee' // later make dynamic with positions selector
      position.startDate = moment(position.startDate).format('YYYY-MM-DD')
      position.status = 'active'
      data.companies = [this.selectedCompany]
      data.name = data.name.trim()
      data.surname = data.surname.trim()
      data.adress = data.adress.trim()
      data.fullAddress = data.adress.concat(', ', data.zip, ' ', data.city)
      data.fullName = data.name.concat(' ', data.surname)
      data.sentBy = this.$store.state.user.email2
      data.department = position.department
      data.project = position.projectID
      position.signedContract = false
      if (position.type === 'custom') position.type = this.customType
      data.status = 'onboarding'
      // get employeeID
      firestore.collection('variables').doc('employeeID').get()
        .then(doc => {
          this.data.employeeID = !this.createCustomID ? String(Number(doc.data().lastID) + 1) : String(this.customID)
          position.id = String(Number(doc.data().lastPositionID) + 1)
          console.log(doc.data(), this.data.employeeID)
          // add new user to firestore
          console.log(data)
          data.positions = []
          data.positions.push(position)
          return firestore.collection('users').add(data)
        }).then(() => {
          // update new employeeID on database
          if (!this.createCustomID) {
            return firestore.collection('variables').doc('employeeID').set({
              lastID: String(this.data.employeeID),
              lastPositionID: String(position.id),
            })
          } else {
            return firestore.collection('variables').doc('employeeID').update({
              lastPositionID: position.id,
            })
          }
        }).then(() => {
          // contract API
          console.log('create contract')
          if (this.createContract) return createContract(data, position, 0)
          return true
        }).then(() => {
          openNotification('Ansatt oprettett', 'Ansatten har blitt oprettett.')
          if (this.isReferred) {
            createRecruitment(data, referredBy)
          }
          if (this.create365) return create365API(data)
          return true
        }).then(() => {
          this.loading = false
          this.resetForm()
          this.fnrValid = false
          this.bankValid = false
        }).catch(err => {
          openNotification('Feil ved oprettelse av ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
          this.loading = false
          console.log(err)
        })
    },
    resetForm() {
      this.data = {}
      this.position =
        {
          type: null,
          startDate: moment(new Date(), 'YYYY-MM-DD'),
        }
      this.customID = ''
    },
    findCity,
    fnrValidate(fnr) {
      let data = fnr
      data = data.replace(/[\s.]/g, '')
      this.data.personalNumber = data
      if (data.length === 11) {
        if (validator.fodselsnummer(this.data.personalNumber)) {
          this.data.birthdate = calculateBirthday(data)
          console.log(this.data)
          this.fnrError = false
          this.fnrValid = true
        } else {
          this.fnrValid = false
          this.fnrError = true
        }
      } else {
        this.fnrValid = false
        this.fnrError = true
      }
    },
    bankValidate() {
      let data = this.data.bankAccount
      data = data.replace(/[\s.]/g, '')
      this.data.bankAccount = data
      if (data.length === 11) {
        if (validator.kontonummer(data)) {
          this.bankError = false
          this.bankValid = true
        } else {
          this.bankValid = false
          this.bankError = true
        }
      } else {
        this.bankValid = false
        this.bankError = true
      }
    },
    selectProject (e) {
      console.log(e)
      const obj = this.contractTypes.find(o => o.value === e)
      console.log(obj)
      this.position.project = obj.project
      this.position.projectID = obj.projectID
      this.position.percentage = obj.percentage
      this.position.salary = obj.salary
      this.data.title = obj.positionTitle
      this.data.roles = obj.defaultRoles
      if (['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        this.avDepartments = selectAvDepartments(obj.department)
      } else {
        this.position.department = this.$store.state.user.department
      }
      this.position.company = obj.company
      this.position.employmentType = obj.employmentType
      this.position.contractId = obj.contractId
      this.position.occupationCode = obj.occupationCode
      this.generateUsernames()
    },
    // to be remade as standard function
    generateUsernames () {
      if (this.data.name && this.data.surname && this.position.type) {
        const name = this.data.name.toLowerCase().replace(/æ/gi, 'ae').replace(/ø/gi, 'oe').replace(/å/gi, 'aa').replace(/[^\w\s]/gi, '')
        const surname = this.data.surname.toLowerCase().replace(/æ/gi, 'ae').replace(/ø/gi, 'oe').replace(/å/gi, 'aa').replace(/[^\w\s]/gi, '')
        const username = name.replace(' ', '').slice(0, 3).concat('.', surname.replace(' ', '').slice(0, 3))
        const domain = this.companies.find(el => el.value === this.position.company).emailDomain
        // Office 365 email
        this.data.companyEmail = username.concat('@', domain)
        // Leaddesk Username
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    onChange(e) {
      const { value } = e.target
      const reg = /^-?[0-9]*(\.[0-9]*)?$/
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        this.value = value
      }
    },
    // '.' at the end or only '-' in the input box.
    onBlur(value) {
      const { onChange } = this
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        onChange({ value: value.slice(0, -1) })
      }
    },
    fetchContracts() {
      firestore.collection('settings').doc('projects').collection('projects')
        .get()
        .then(snapshot => {
          let i = 0
          snapshot.forEach(doc1 => {
            if ([doc1.data().company].some(r => this.$store.state.user.companies.indexOf(r) >= 0)) {
              firestore.collection('settings').doc('contracts').collection('contracts').where('project', '==', doc1.id).orderBy('title', 'asc').get()
                .then(snapshot => {
                  const children = []
                  snapshot.forEach(doc2 => {
                    if ((['HRcreateUsersDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0) && [this.$store.state.user.department].some(r => doc2.data().department.indexOf(r) >= 0)) ||
                        ['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                      if ([doc2.data().role].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                        const child = doc2.data()
                        child.value = doc2.data().title
                        child.project = doc1.data().name
                        child.projectID = doc1.data().id
                        child.contractId = doc2.id
                        this.contractTypes.push(child)
                        children.push(child)
                      }
                    }
                  })
                  if (children.length) {
                    this.positionsSelector.push({
                      title: doc1.data().name,
                      company: doc1.data().company,
                      key: i,
                      children,
                    })
                    i += 1
                  }
                })
            }
          })
          console.log(this.contractTypes)
          this.loaded = true
        })
    },
    fetchUsers() {
      firestore.collection('users').where('status', '==', 'active').where('companies', 'array-contains', 'Skycall').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const user = {
              value: String(doc.data().employeeID),
              text: String(doc.data().fullName),
              key: String(doc.data().employeeID),
            }
            this.users.push(user)
          })
        })
    },
  },
  watch: {
    // whenever question changes, this function will run
    'position.type'(newValue) {
      this.selectProject(newValue)
    },
  },
}
</script>

<style scoped>
  .validfield {
    border-color: green!important
  }
  .invalidfield {
    border-color: red!important
  }
</style>
